<template>
    <page title="Property Areas" icon="mdi-office-building">
        <template v-slot:header>
            <toolbar>
                <add-page
                    v-if="formModel"
                    add-dialog-title="Add Property Area"
                    collection-name="propertyArea"
                    :form-model="formModel"
                    :base-url="baseUrl"
                    :done-url="doneUrl"
                />
            </toolbar>
        </template>
        <v-treeview
            v-if="propertyAreaTree && formModel"
            :items="propertyAreaTree"
            dense
            transition
            activatable
            open-all
            :active.sync="active"
            v-on:update:active="clickRow"
        >
            <template v-slot:prepend="{ item }">
                <div class="d-flex flex-row">
                    <div
                        :class="
                            item.propertyAreaType === 'group'
                                ? 'font-weight-bold orange--text darken-4 mx-2'
                                : 'font-weight-bold primary--text mx-2'
                        "
                    >
                        {{ item.fullPropertyAreaCode }}
                    </div>
                    {{ item.fullPropertyAreaName }}
                    <div  class="font-weight-bold mx-2">{{item.netLetArea }}</div>
                </div>
            </template>
        </v-treeview>
        <!--        <debug :value="propertyAreaTree" />-->
    </page>
</template>

<script>
import api from '@/api';

import AddPage from '../../../components/pages/Add';

export default {
    name: 'PropertyList',
    components: { AddPage },
    props: {
        propertyId: String,
        propertyAreaId: String,

        baseUrl: String,
        doneUrl: String,
    },
    data() {
        return {
            active: [],
            selectedItemId: null,
            propertyAreaTree: null,
            formModel: null,
        };
    },
    methods: {
        clickRow(item) {
            console.log('clickRow', { item });
            this.selectedItemId = item.length ? item[0] : null;
            console.log({ selectedItemId: this.selectedItemId });
            if (this.selectedItemId && this.selectedItemId !== this.propertyAreaId) {
                const url = `/auth/admin/${this.baseUrl}/${this.selectedItemId}`;
                console.log('clickrow', { url });
                this.$router.push(url);
            }
        },
        async init() {
            if (this.propertyId) {
                const result = await api.get(this.$store, `${this.baseUrl}`);

                if (result.data) {
                    console.log('loaded property areas ', { data: result.data });
                    this.propertyAreaTree = result.data;
                    this.formModel =
                        this.propertyAreaTree && this.propertyAreaTree.length
                            ? { propertyLink: this.propertyAreaTree[0].propertyLink }
                            : {};
                    console.log('propertyAreaList form model', this.formModel);
                }
            }
        },
    },
    async mounted() {
        console.log('propertyAreaList mounted', this.propertyId, this.baseUrl, this.doneUrl);
        await this.init();
    },
};
</script>

<style scoped></style>
